import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import * as config from "../../constants/config"
import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import callApi from "../../Utils/AxiosAPI";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });

  const [aboutData, setAboutData] = useState({})

  useEffect(() => {
    callApi("about", "get").then((res) => {
      if (res.data) {
        setAboutData(res.data)
      }
    })
  }, [])

  return (
    <div style={{
      height: "300px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      paddingLeft: "50px"
    }}>
      <div
        style={{
          width: "200px"
        }}
      >
        <img style={{
          height: "100px",
          width: "100px"
        }}
          src={`${config.API_URL}/uploads/logo_e39349010a.jfif?27924701.4`} />
      </div>

      <div
        style={{
          width: "calc(100% - 200px)",
          display: "flex",
          justifyContent: "space-around"
        }}
      >

        <div style={{
          height: "100%",
          width: "150px",
        }}>
          <div style={{
            fontSize: "20px",
            fontWeight: "bold",
            marginBottom: "10px"
          }}>HOME PAGES</div>
          <div
            style={{
              cursor: "pointer"
            }}
          ><a href='/'>Home Page</a></div>
          <div
            style={{
              cursor: "pointer"
            }}
          ><a href='/feedbacksurvey'>Survey</a></div>
          <div
            style={{
              cursor: "pointer"
            }}
          ><a href='/chargingnavigator'>Charging Stations</a></div>
        </div>

        <div style={{
          height: "100%",
          width: "150px",
        }}>
          <div style={{
            fontSize: "20px",
            fontWeight: "bold",
            marginBottom: "10px"
          }}>CONTACT US</div>
          <div
            style={{
              cursor: "pointer"
            }}
          ><a href='https://gaiatechintl.com/contact-us'>Contact Us</a></div>
          <div
            style={{
              cursor: "pointer"
            }}
          ><a href='/disclaimer'>Disclaimer</a></div>
        </div>

        <div style={{
          height: "100%",
          width: "150px",
        }}>
          <div style={{
            fontSize: "20px",
            fontWeight: "bold",
            marginBottom: "10px"
          }}>ABOUT</div>
          <div
            style={{
              cursor: "pointer"
            }}
          ><a href='https://gaiatechintl.com'>About Organisation</a></div>
          <div
            style={{
              cursor: "pointer"
            }}
          ><a href='https://gaiatechintl.com/about-us'>Who We Are</a></div>
          <div
            style={{
              cursor: "pointer"
            }}
          ><a href='/about'>Our Partners</a></div>
        </div>
        <div style={{
          height: "100%",
          width: "150px",
        }}>
          <div style={{
            fontSize: "20px",
            fontWeight: "bold",
            marginBottom: "10px"
          }}>COMMUNITY</div>
          <div
            style={{
              cursor: "pointer"
            }}
          ><a href='/forumblogs'>Forums</a></div>
          <div style={{ color: 'grey' }}>Newsletter (Comming Soon)</div>
        </div>
      </div>

    </div>
  );
}
Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
