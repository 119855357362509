import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import PersonIcon from '@material-ui/icons/Person';
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import image from "assets/img/HomeBanner1.jpg";
import callApi from "Utils/AxiosAPI";
import { Close, ContactSupport } from "@material-ui/icons";
import { IconButton, Snackbar } from "@material-ui/core";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import NotifyModal from "views/Common-Sections/NotifyModal";

const useStyles = makeStyles(styles);

export default function RegisterPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [username, setUsername] = useState("");
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;
  const onReturnHome = e => {
    props.history.push('/')
  }
  const handleSubmit = e => {
    e.preventDefault();
    if (confirmpassword != password) {
      setShowPasswordError(true)
      return;
    }
    const data = {
      username: username,
      email: email,
      password: password
    };
    setShowMessage(false);
    setShowEmailError(false);
    setShowPasswordError(false);
    callApi('auth/local/register', 'POST', null, data).then(res => {
      setShowMessage(true)
    }).catch(err => {
      setShowEmailError(true)
    })
  };
  return (
    <div>
      <Header
        absolute
        color="transparent"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4} style={{ maxWidth: '50% !important' }}>
              <Card className={classes[cardAnimaton]}>
                <form onSubmit={handleSubmit} >
                  <CardHeader color="primary" className={classes.cardHeader} style={{ minHeight: '100px' }}>
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      style={{
                        float: 'right',
                        padding: '0',
                        top: '-10px',
                        right: '10px'
                      }}
                      onClick={onReturnHome}
                    >
                      <Close className={classes.modalClose} />
                    </IconButton>
                    <h4 style={{
                      marginTop: '1em',
                      lineHeight: '2em',
                      marginLeft: '1.8em'
                    }}>
                      BatteryGoWhere
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <div>
                      {
                        showEmailError ?
                          <SnackbarContent
                            message={
                              <span>
                                <b>User name / Email already exits</b>
                              </span>
                            }
                            close
                            color="danger"
                          /> : ''
                      }
                      {
                        showPasswordError ?
                          <SnackbarContent
                            message={
                              <span>
                                <b>Confirm password not matching</b>
                              </span>
                            }
                            close
                            color="danger"
                          /> : ''
                      }
                    </div>
                    <CustomInput
                      labelText="Username..."
                      id="Username"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event => setUsername(event.target.value),
                        type: "text",
                        endAdornment: (
                          <InputAdornment position="end">
                            <PersonIcon className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event => setEmail(event.target.value),
                        type: "email",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),

                      }}
                    />
                    <CustomInput
                      labelText="Password"
                      id="Password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event => setpassword(event.target.value),
                        type: "password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                    <CustomInput
                      labelText="Confirm Password"
                      id="ConfirmPassword"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event => setConfirmpassword(event.target.value),
                        type: "password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button type="submit" color="primary" size="lg">
                      Register
                    </Button>
                    {/* <NotifyModal content={"testing"}/> */}
                    <NotifyModal open={showMessage} />
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
