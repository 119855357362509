import { Grid } from '@material-ui/core';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import React, { useEffect, useState } from 'react'

import { withGoogleMap, withScriptjs, GoogleMap, Marker, InfoWindow } from "react-google-maps"
function MapSection(props) {
  var { positions } = props;
  const [selectedposition, setSelectedPark] = useState(null);
  const [IFWlat, setIFWlat] = useState(null);
  const [IFWlng, setIFWlng] = useState(null);
  useEffect(() => {
    const listener = e => {
      if (e.key === "Escape") {
        setSelectedPark(null);
        setIFWlat(null);
        setIFWlng(null);
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{ lat: 1.3526658554121238, lng: 103.85973971292402 }}>
      {
        positions && positions.length > 0 && positions.map((position) =>
          <Marker
            key={position.id}
            position={{
              lat: parseFloat(position.latitude),
              lng: parseFloat(position.longtitude)
            }}
            onClick={() => {
              setSelectedPark(null);
              setSelectedPark({ position });
              setIFWlat(parseFloat(position.latitude));
              setIFWlng(parseFloat(position.longtitude))
            }}
            icon={{
              url: `/MarkerPointer.png`,
              scaledSize: new window.google.maps.Size(30, 30)
            }}
          />
        )
      }
      {selectedposition && IFWlat && IFWlng && (
        <InfoWindow style={{ marginbottom: '10px' }}

          onCloseClick={() => {
            setSelectedPark(null);
            setIFWlat(null);
            setIFWlng(null);
          }}
          position={{
            lat: parseFloat(IFWlat),
            lng: parseFloat(IFWlng)
          }}
        >
          <Grid style={{ fontFamily: 'Roboto,Arial', color: 'black', fontSize: '10px' }}>
            {/* <Card style={{ width: "20rem" }}>
              <CardHeader color="warning">{selectedposition.position.name}</CardHeader>
              <CardBody>
                <h4>{selectedposition.position.address}</h4>
                <p>
                  {selectedposition.position.city}    {selectedposition.position.postalCode}
                </p>
              </CardBody>
            </Card> */}
            <p ><b>{selectedposition.position.name}</b></p>
            <hr/>
            <div style={{ fontSize: '9px !important' }} >
              <p style={{ margin: '0', padding: '0' }}>{selectedposition.position.address}</p>
              <p style={{ margin: '0', padding: '0' }}>{selectedposition.position.city}    {selectedposition.position.postalCode}</p>
            </div>
          </Grid>
        </InfoWindow>
      )}
    </GoogleMap>
  );
}

export default withScriptjs(withGoogleMap(MapSection));