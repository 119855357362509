import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import callApi from "../../../Utils/AxiosAPI";
import emailIcon from "assets/img/icons8-email-30.png";
import aboutUsImg from "assets/img/aboutus-section-bg.png";
import * as Config from '../../../constants/config'
import '../../../assets/css/StrapiArticle.css'
const useStyles = makeStyles(styles);

export default function AboutSection() {
  const classes = useStyles();

  const [aboutData, setAboutData] = useState({})

  useEffect(() => {
    callApi("about", "get").then((res) => {
      console.log(res);
      if (res.data) {
        setAboutData(res.data);
      }
    })
  }, [])

  useEffect(() => {
    if (aboutData.Corporate) {
      document.getElementById("content").insertAdjacentHTML('beforeend', aboutData.Corporate?.CompanyShortDesc);
    }
    return () => {
      document.getElementById("content").textContent = "";
    }
  }, [aboutData.Corporate?.CompanyShortDesc])

  return (
    <GridContainer justify="center">
      <div style={{
        height: "1700px",
        width: "100%",
        paddingTop: "50px"
      }}>
        <img
          style={{
            height: "500px",
            width: "100%"
          }}
          src="https://images.adsttc.com/media/images/5cf6/7ca5/284d/d15e/a900/024b/large_jpg/Ultra-fast_charging_station_02_credit_COBE_and_Rasmus_Hjortsh%C3%B5j_-_COAST.jpg?1559657560" />

        <div style={{
          color: "black",
          width: "100%",
          marginTop: "50px",
          display: "flex",
          height: "700px",
          minWidth: "700px",
          backgroundImage: `url(${aboutUsImg})`,
          backgroundRepeat: `no-repeat`,
          backgroundPosition: `center`
        }}>

          <div style={{
            height: "570px",
            width: "570px",
            borderRadius: "1000px",
            marginTop: "60px",
            paddingTop: "123px",
            backgroundColor: "#ffffff91"
          }}>
            <div style={{
              height: "80px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "inherit",
              fontWeight: "500",
              lineHeight: "1.1",
              color: "#0c0633",
              fontSize: "50px",
            }}>About Us</div>

            <div
              id="content"
              className="strapilink"
              style={{
                width: "470px",
                display: "flex",
                justifyContent: "center",
                color: "black",
                paddingTop: "10px",
                paddingBottom: "70px",
                paddingLeft: "95px",
                fontSize: '16px !important'
              }}>
            </div>

          </div>

          <div style={{
            backgroundColor: "#2b6956",
            width: "400px",
            height: "170px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "450px",
            marginLeft:"50px",
            borderRadius:"3px"
          }}>
            <div>
              <div style={{ width: "100%", display: "flex", }}>
                <div style={{ width: "50px" }}>
                  <img
                    style={{ width: "30px", height: "30px", marginRight: "20px" }}
                    src={emailIcon} />
                </div>
                <div >{aboutData?.Contact?.BGWContactEmail}</div>
              </div>
              <div style={{ width: "100%", display: "flex", marginTop: "20px" }}>
                <div style={{ width: "50px" }}>
                  <img
                    style={{ width: "30px", height: "30px", marginRight: "20px" }}
                    src={emailIcon} />
                </div>
                <div >{aboutData?.Contact?.ContactShortDesc}</div>
              </div>
              <div style={{ width: "100%", display: "flex", marginTop: "20px" }}>
                <div style={{ width: "50px" }}>
                  <img
                    style={{ width: "30px", height: "30px", marginRight: "20px" }}
                    src={emailIcon} />
                </div>
                <div >{aboutData?.Contact?.GTPLContactEmail}</div>
              </div>
            </div>
          </div>

        </div>

        {/* <div style={{
          color: "black",
          width: "100%",
          padding: "0px 100px",
          marginTop: "50px",
          display: "flex",
        }}>
          <div style={{ width: "50%", paddingTop: "50px" }}>
            <div style={{
              backgroundColor: "#A0522D",
              width: "100%",
              height: "80%"
            }}>
              <div
                id="content"
                className="strapilink"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  paddingTop: "70px",
                  paddingBottom: "70px",
                  paddingLeft: "10px",
                  fontSize: '16px !important'
                }}>
              </div>
              <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={emailIcon} />
                <div style={{ color: "white", display: "flex", alignItems: "center", marginLeft: "10px" }}>
                  {aboutData?.Corporate?.GTPL_URL}
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div style={{
              backgroundColor: "#2b6956",
              width: "100%",
              height: "100%",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
              <div>
                <div style={{ width: "100%", display: "flex", marginTop: "20px" }}>
                  <div style={{ width: "50px" }}>
                    <img
                      style={{ width: "30px", height: "30px", marginRight: "20px" }}
                      src={emailIcon} />
                  </div>
                  <div >{aboutData?.Contact?.BGWContactEmail}</div>
                </div>
                <div style={{ width: "100%", display: "flex", marginTop: "20px" }}>
                  <div style={{ width: "50px" }}>
                    <img
                      style={{ width: "30px", height: "30px", marginRight: "20px" }}
                      src={emailIcon} />
                  </div>
                  <div >{aboutData?.Contact?.ContactShortDesc}</div>
                </div>
                <div style={{ width: "100%", display: "flex", marginTop: "20px" }}>
                  <div style={{ width: "50px" }}>
                    <img
                      style={{ width: "30px", height: "30px", marginRight: "20px" }}
                      src={emailIcon} />
                  </div>
                  <div >{aboutData?.Contact?.GTPLContactEmail}</div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div style={{
          width: "100%",
          height: "150",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <div style={{
            fontFamily: "inherit",
            fontWeight: "500",
            lineHeight: "1.1",
            color: "#0c0633",
            fontSize: "50px",
            marginTop: "80px"
          }}>
            Our Partners
          </div>
        </div>

        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          height: "250px"
        }}>
          <div>
            <img src={`${Config.API_URL}${aboutData?.Partners?.ScrollingPartnerLogo[0].url}`}
              style={{
                height: "115px",
                width: "130px"
              }} />
          </div>

          <div>
            <img src={`${Config.API_URL}${aboutData?.Partners?.ScrollingPartnerLogo[1].url}`}
              style={{
                height: "100px",
                width: "250px"
              }} />
          </div>

          <div>
            <img src={`${Config.API_URL}${aboutData?.Partners?.ScrollingPartnerLogo[2].url}`}
              style={{
                height: "76px",
                width: "248px"
              }} />
          </div>


        </div>

      </div>
    </GridContainer>
  );
}