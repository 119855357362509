import React, { Component } from 'react'
import callApi from "Utils/AxiosAPI.js";
import MapSection from './MapSection';
export default class ShowMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            positions: [],
            APIKey: ''
        };
    }
    componentDidMount() {
        callApi('config-commons', 'get')
            .then(res => {
                this.setState({
                    APIKey: res.data[3].value
                })

                let stationAmount = (res.data[5] 
                    && res.data[5].value 
                    && res.data[5].value !== "null"  
                    && res.data[5].value !== null) 
                ? res.data[5].value 
                : -1 ;

                callApi(`location-temps?_limit=${stationAmount}`, 'get')
                .then(res => {
                    this.setState({
                        positions: res.data
                    })
                });
            });
    }

    render() {
        if (this.state.positions && this.state.positions.length > 0 && this.state.APIKey) {
            const key = this.state.APIKey;
            return (
                <MapSection
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}`}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={
                        <div style={{
                            height: `83vh`,
                            border: '2px solid black',
                        }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    positions={this.state.positions}
                />
            )
        }
        return (<div></div>)
    }
}