import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import Card from "components/Card/Card";
import callApi from "Utils/AxiosAPI";
import { useHistory } from "react-router";

const useStyles = makeStyles(styles);

export default function EVAdvisorSection() {
    const [botId, setBotId] = useState('');
    const [tenantId, setTenantId] = useState(null);
    const history = useHistory();


    useEffect(() => {
        callApi('config-commons', 'GET').then(res => {
            setTenantId(res.data[1].value)
            setBotId(res.data[0].value.replaceAll('-', ''))
            if (localStorage.getItem('username') == null) {
                history.push('/')
            }
        }, [])
    }, [])

    const BotURL = `https://web.powerva.microsoft.com/environments/Default-${tenantId}/bots/new_bot_${botId}/webchat`
    return (
        <div style={{ marginTop: '-50px' }}>
            <GridContainer justify="center" style={{ height: '780px' }}>
                <GridItem xs={12} sm={12} md={8}>
                    <Card style={{ marginTop:'80px', height: '625px' }}>
                        {
                            botId && tenantId ?
                                <iframe height="100%" width="100%" src={BotURL} />
                                : ''
                        }
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
