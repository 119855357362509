import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import callApi from "Utils/AxiosAPI.js";
import { useHistory } from "react-router-dom";
import * as Config from '../../../constants/config'

const useStyles = makeStyles(styles);

export default function BlogSection() {
  const history = useHistory();
  const classes = useStyles();

  const [blogList, setBlogList] = useState([]);

  useEffect(() => {
    callApi("articles?category.slug=forum-blog&_sort=created_at:ASC", "get")
      .then(res => {
        if (res && res.data) setBlogList(res.data)
      })
  }, [])

  const blogOnClick = (slug) => {
    history.push('/article/' + slug)
  }

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <div style={{
          // height:"1500px",
          width: "100%",
          paddingTop: "50px"
        }}>

          {blogList.length > 0 && blogList.map(blog => (
            <div style={{
              height: "120px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "30px 0px",
            }}
              onClick={() => {
                blogOnClick(blog.slug)
              }}
            >
              <div style={{
                height: "100%",
                width: "100%",
                display: "flex",
                backgroundColor: "#f9f9f9",
                borderRadius: "10px",
                minWidth: "730px",

                boxShadow: "0 0px 0px 0px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)",
                cursor: "pointer",
                maxWidth: "800px "
              }}>
                <div style={{
                  width: "120px",
                  height: "100%",
                }}>
                  <img src={`${Config.API_URL}${blog.image.url}`}
                    style={{
                      height: "100%",
                      width: "100%"
                    }} />
                </div>
                <div style={{
                  width: "calc(100% - 120px)",
                  height: "100%",
                  color: "black",
                  padding: "10px 20px",
                  display: "flex",
                }}>
                  <div style={{
                    width: "80%",
                    height: "100%",
                    color: "black",
                    overflow: "hidden",
                  }}>
                    <div style={{
                      fontSize:"16px"
                    }}>
                      {blog.title}
                    </div>
                    <div style={{
                      fontSize:"14px"
                    }}>
                      Author: {blog.author?.name ? blog.author?.name : (<i>anonymous</i>)}
                    </div>
                  </div>
                  <div style={{
                    width: "20%",
                    height: "100%",
                    color: "black",
                    paddingTop: "25px",
                    paddingLeft: "13px",
                    fontSize: "13px"
                  }}>
                    <div>Views: {blog.viewCount ? blog.viewCount : 0}</div>
                    <div>Comments: {blog.commentCount ? blog.commentCount : 0}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </GridContainer>
    </div>
  );
}