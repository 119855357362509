import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import AboutPage from "views/AboutPage/AboutPage.js";
import NewsPage from "views/NewsPage/NewsPage";
import BlogPage from "views/BlogPage/BlogPage";
import SurveyPage from "views/SurveyPage/SurveyPage";
import ChargingAdvisoryPage from "views/ChargingAdvisoryPage/ChargingAdvisoryPage";
import Components from "views/Components/Components";
import RegisterPage from "views/RegisterPage/RegisterPage";
import ChatBot from "components/ChatBot/ChatBot";
import ArticlePage from "views/ArticlePage/ArticlePage";
import EVAvisorPage from "views/EVAvisor/EVAvisorPage";
import MyAccount from "views/MyAccount/MyAccount";
import DisclaimerSection from "views/Common-Sections/DisclaimerSection";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide } from "@material-ui/core";
import { Close, LibraryBooks } from "@material-ui/icons";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-react/views/componentsSections/javascriptStyles.js";
import { makeStyles } from "@material-ui/core/styles";
import callApi from "Utils/AxiosAPI";
import ReactGA from "react-ga4";

var hist = createBrowserHistory();
const useStyles = makeStyles(styles);


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const Main = () => {
  const [GAKey, setGAKey] = useState(null)
  const [isShowChatBot, setIsShowChatBot] = useState(false);
  const classes = useStyles();
  const [classicModal, setClassicModal] = React.useState(false);
  const [botMessage, setBotMessage] = useState(null)
  const [loaded, setLoaded] = useState(false)
  if (GAKey) {
    hist.listen(location => {
      ReactGA.initialize(GAKey);
      ReactGA.set({ page: location.pathname });
      ReactGA.send(location.pathname);
    });
  }
  useEffect(() => {
    callApi('config-commons', 'GET').then(res => {
      setGAKey(res.data[4].value)
    })
  }, [])
  useEffect(() => {
    if (GAKey) {
      ReactGA.initialize(GAKey);
      ReactGA.send(window.location.pathname);
    }
  }, GAKey)

  useEffect(() => {
    if (botMessage == null) {
      callApi('ev-advisor', 'GET').then(res => {
        setBotMessage(res.data.Introduction.AAA)
      })
    }
  }, [])
  if (document.getElementById("botcontent") && !loaded && botMessage) {
    document.getElementById("botcontent").insertAdjacentHTML('beforeend', botMessage);
    setLoaded(true)
  }
  return (
    <Router history={hist}>
      <Switch>
        {/* <Route path="/landing-page" component={LandingPage} /> */}
        <Route exact path="/profile-page" component={ProfilePage} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/register" component={RegisterPage} />
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/about" component={AboutPage} />
        <Route exact path="/news" component={NewsPage} />
        <Route exact path="/forumblogs" component={BlogPage} />
        <Route exact path="/chargingnavigator" component={ChargingAdvisoryPage} />
        <Route exact path="/feedbacksurvey" component={SurveyPage} />
        <Route exact path="/component" component={Components} />
        <Route exact path="/article/:slug" component={ArticlePage} />
        <Route exact path="/evadvisor" component={EVAvisorPage} />
        <Route exact path="/myaccount" component={MyAccount} />
        <Route exact path="/component" component={Components} />
        <Route exact path="/disclaimer" component={DisclaimerSection} />
      </Switch>
      <div style={{
        position: "fixed",
        right: 0,
        zIndex: 9,
        top: 120,
        borderRadius: "50px",
        border: "none",
        marginRight: '5px',
        cursor: "pointer"
      }}
        color="info"
        onClick={() => {
          setIsShowChatBot(localStorage.getItem('username') ? !isShowChatBot : false)
          setClassicModal(localStorage.getItem('username') ? false : true)
        }}
      >
        <img height="50px" width="50px" src="/botIcon.png" />
      </div>

      {isShowChatBot && <div style={{ backgroundColor: "white", position: "fixed", right: 0, top: 170, zIndex: 9 }}>
        <ChatBot />
      </div>}
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={classicModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setClassicModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setClassicModal(false)}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>BatteryGoWhere</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}>
          <div id='botcontent'></div>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button href="/register" color="primary" simple>
            Register
          </Button>
          <Button
            onClick={() => setClassicModal(false)}
            color="danger"
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Router>
  )
}
{/* <div id='botcontent'></div> */ }

export default Main;