import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import * as Config from "./../../constants/config";
import "./../../assets/css/StrapiArticle.css"
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import callApi from "Utils/AxiosAPI";
import { useParams } from "react-router-dom";
const useStyles = makeStyles(styles);

export default function ArticlePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [articleData, setArticleData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const { slug } = useParams();
  const endpoint = 'articles/' + slug;
  useEffect(() => {
    slug ?
      callApi(endpoint, "get").then((res) => {
        setArticleData(res.data)
      })
      : ''
  }, []);
  
  const imageSrc = `${Config.API_URL}${articleData?.image?.url}`;
  
  if (document.getElementById("articalcontent") && !loaded) {
    document.getElementById("articalcontent").insertAdjacentHTML('beforeend', articleData?.content);
    setLoaded(true)
  }
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        // brand="BGW"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)} >
        <div className={classes.container} >
          <GridContainer justify="center" >
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.profile}>
                <div>
                  <img src={imageSrc} alt="..." className={imageClasses} style={{ maxWidth: '280px' }} />
                </div>
                <div className={classes.name}>
                  <h2 className={classes.title} >{articleData?.title}</h2>
                  <div>
                    {
                      articleData?.author?.name ?
                        <h3 className={classes.smallTitle}>
                          Author: <b style={{ fontWeight: '500' }}>{articleData?.author?.name}</b>
                        </h3>
                        :
                        <h3 className={classes.smallTitle}>
                          Author: (<i>anonymous</i>)
                        </h3>
                    }
                  </div>
                  <br />
                </div>
              </div>
            </GridItem>
          </GridContainer>
          <div>
            <div className="strapiarticle" id='articalcontent'></div>
            <br />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
