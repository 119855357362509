import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import callApi from "Utils/AxiosAPI.js";
import * as Config from "./../../constants/config";
import EVAdvisorSection from "./Sections/EVAdvisorSection";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function EVAvisorPage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const [homepage, GetHomePage] = useState();
    useEffect(() => {
        callApi("homepage", "get").then((res) => {
            GetHomePage(res.data);
        });
    }, []);

    const imageSrc = `${Config.API_URL}${homepage?.BannerImage.Image.url}`;
    return (
        <div>
            <Header
                color="transparent"
                routes={dashboardRoutes}
                // brand="BGW"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 100,
                    color: "white",
                }}
                {...rest}
            />
            <Parallax small filter image={imageSrc}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <h2 className={classes.title}>
                                {homepage?.BannerImage.BannerTitle}
                            </h2>
                            <div>{homepage?.BannerImage.BannerDescription}</div>
                            <br />
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <EVAdvisorSection />
                </div>
            </div>
            <Footer />
        </div>
    );
}
