import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function SurveySection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center" style={{height:'800px'}}>
        <GridItem xs={12} sm={12} md={8}>
          <iframe height="100%" width="100%" src="https://docs.google.com/forms/d/e/1FAIpQLSdVHRv1hvJobM80cp2b_IQQkp33BP837hUr_olpm-kKmUUnAg/viewform?usp=sf_link"/>
        </GridItem>
      </GridContainer>
    </div>
  );
}
