import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import callApi from "Utils/AxiosAPI.js";
import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";
import CardHeader from "components/Card/CardHeader";
import * as Config from '../../../constants/config'

const useStyles = makeStyles(styles);

export default function PoliciesSection(props) {
  const history = useHistory();
  const classes = useStyles();
  const [Articles, GetArticle] = useState();
  const data = {
    "category.slug": props.categorySlug,
    '_limit': 3,
    '_sort': 'created_at:ASC'
  };
  useEffect(() => {
    callApi("articles", "get", data).then((res) => {
      GetArticle(res.data);
    });
  }, []);
  const ReadMore = e => {
    history.push('/article/' + e)
  }
  return (
    <Card >
      <CardHeader color="warning">
        <h3 className={classes.title}>{props?.title}</h3>
      </CardHeader>
      <CardBody>
        <div className={classes.section} style={{ marginTop: '-50px' }}>
          <GridContainer >
            {
              Articles?.slice(0, 3).map((article, index) => {
                return (
                  <GridItem xs={12} sm={12} md={4} key={index}>
                    <Card style={{ height: '100%' }} >
                      <GridItem className={classes.itemGrid} style={{ marginTop: '20px' }}>
                        <img
                          style={{ height: "180px", width: "90%", display: "block", marginLeft: "auto", marginRight: "auto" }}
                          className={classes.imgCardTop}
                          src={`${Config.API_URL}${article?.image.url}`}
                          alt="Card-img-cap"
                        />
                      </GridItem>
                      <h3 className={classes.cardTitle}>
                        <h4 style={{ minHeight: '80px', fontWeight: '400', paddingLeft: '10px', paddingRight: '10px' }}>
                          {article.title}
                        </h4>
                        <hr style={{ margin: '0px 10px' }} />
                        {
                          article?.author?.name ?
                            <h4 className={classes.smallTitle}>
                              Author: <b style={{ fontWeight: '500' }}>{article?.author?.name}</b>
                            </h4>
                            :
                            <h4 className={classes.smallTitle}>
                              Author: (<i>anonymous</i>)
                            </h4>
                        }
                        <hr style={{ margin: '0px 10px' }} />
                      </h3>
                      <CardBody>
                        <h4 className={classes.description}>{article.description.substring(0, 150)}...</h4>
                      </CardBody>
                      <CardFooter className={classes.justifyCenter}>
                        <Button onClick={() => ReadMore(article?.slug)} size="lg" color="success">Read More</Button>
                      </CardFooter>
                    </Card>
                  </GridItem>
                )
              })
            }
          </GridContainer>
        </div>
      </CardBody>
    </Card >
  );
}
