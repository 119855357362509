// import React from 'react'

// const ChatBot = () => {
//     return (
//         <div style={{ display: "block", marginBottom: '-6px' }}>
//             <iframe height="340px" width="310px" src="https://web.powerva.microsoft.com/environments/Default-508b6a8f-c842-4cb9-9b94-1c3af539c7eb/bots/new_bot_b7692625c2fb4ca6a4ebedb0bfb6a18e/webchat"></iframe>
//         </div>
//     )
// }
// export default ChatBot

import React, { useEffect, useState } from 'react'
import callApi from 'Utils/AxiosAPI'

// function replaceAll(str, find, replace) {
//     return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
// }
// str = str.replace(/abc/g, '');
export default function ChatBot() {
    const [botId, setBotId] = useState('');
    const [tenantId, setTenantId] = useState(null);

    useEffect(() => {
        callApi('config-commons', 'GET').then(res => {
            setTenantId(res.data[1].value)
            setBotId(res.data[0].value.replaceAll('-', ''))
        }, [])
    }, [])


    const BotURL = `https://web.powerva.microsoft.com/environments/Default-${tenantId}/bots/new_bot_${botId}/webchat`
    return (
        <div style={{ display: "block", marginBottom: '-6px' }}>
            {
                botId && tenantId ? <iframe height="340px" width="310px" src={BotURL}></iframe> : ''
            }
        </div>
    )
}
