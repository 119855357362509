import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
// import GridContainer from "components/Grid/GridContainer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";


import styles from "assets/jss/material-kit-react/views/profilePage.js";
import ShowMap from "./Sections/ShowMap";

const useStyles = makeStyles(styles);

export default function ChargingAdvisoryPage(props) {
    const classes = useStyles();
    const { ...rest } = props;

    return (
        <div>
            <Header
                color="white"
                // brand="BGW"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 0,
                    color: "white",
                }}
                {...rest}
            />
            <div className={classNames(classes.main, classes.mainRaised)} style={{ marginTop: '6%' }}>
                <div>
                    <ShowMap />
                </div>
            </div>
            <Footer />
        </div>
    );
}
