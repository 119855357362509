import React from "react";
import ReactDOM from "react-dom";
import "assets/scss/material-kit-react.scss?v=1.10.0";
import Main from "./main"

ReactDOM.render(
  <Main />
  ,
  document.getElementById("root")
);
