import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import callApi from "Utils/AxiosAPI.js";
import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";
import * as Config from './../../../constants/config'

const useStyles = makeStyles(styles);

export default function EVLogoSection() {
  const classes = useStyles();

  const [homepage, GetHomePage] = useState();
  // const data = {
  //   "category.slug": props.categorySlug,
  //   '_limit': 3,
  //   '_sort': 'created_at:ASC'
  // };
  useEffect(() => {
    callApi("homepage", "get").then((res) => {
      GetHomePage(res.data);
    });
  }, []);

  return (
    <div className={classes.section}>
      <div>
        <GridContainer >
          {
            homepage?.EVMakerLogo.LogoImage.slice(0, 6).map((logoImage, index) => {
              return (
                <GridItem xs={2} sm={2} md={2} key={index}>
                  <GridItem>
                    <img
                      className={classes.imgCardTop}
                      src={`${Config.API_URL}${logoImage?.url}`}
                      alt="Card-img-cap"
                    />
                  </GridItem>
                </GridItem>
              )
            })
          }
        </GridContainer>
      </div>
    </div>
  );
}
