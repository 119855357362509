import axios from "axios";
import * as Config from "./../constants/config";
export default function callApi(endpoint, method, data, body) {
  return axios({
    method: method,
    url: `${Config.API_URL}/${endpoint}`,
    params: data,
    data: body
  })
}
