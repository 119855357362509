import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/HomeBanner1.jpg";
import callApi from "Utils/AxiosAPI";
import { Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import NotifyModal from "views/Common-Sections/NotifyModal";
import LoginErrModal from "views/Common-Sections/LoginErrModal";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);

  const [showLoginErrMessage, setShowLoginErrMessage] = useState(false);
  const [message, setMessage] = useState("");


  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  const classes = useStyles();
  const { ...rest } = props;

  const onReturnHome = e => {
    props.history.push('/')
  }
  const handleSubmit = e => {
    e.preventDefault();
    const data = {
      identifier: email,
      password: password
    };
    if (!email) {
      setEmailErr(true);
      return;
    }
    else

      if (!password) {
        setPasswordErr(true);
        return;
      }
    setMessage('');
    setEmailErr(false);
    setPasswordErr(false);
    setShowLoginErrMessage(false);
    callApi('auth/local', 'POST', null, data).then(res => {
      console.log(res)
      document.cookie = "loginstatus=loggedin";
      localStorage.setItem('token', res?.data?.jwt)
      localStorage.setItem('username', res?.data?.user?.username)
      props.history.push('/')
    }).catch(err => {
      if (err.response.status == 400) {
        if (err.response.data.message[0].messages[0].id == "Auth.form.error.confirmed") {
          setMessage("Your account is not verified.")
        }
        else {
          setMessage("Your email or password is invalid")
        }
      }

      setShowLoginErrMessage(true)
    })
  };
  return (
    <div>
      <Header
        absolute
        color="transparent"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}>

        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4} style={{ maxWidth: '50% !important' }}>
              <Card className={classes[cardAnimaton]}>
                <form onSubmit={handleSubmit} >
                  <CardHeader color="primary" className={classes.cardHeader} style={{ minHeight: '100px' }}>
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      style={{
                        float: 'right',
                        padding: '0',
                        top: '-10px',
                        right: '10px'
                      }}
                      onClick={onReturnHome}
                    >
                      <Close className={classes.modalClose} />
                    </IconButton>
                    <h4 style={{
                      marginTop: '1em',
                      lineHeight: '2em',
                      marginLeft: '1.8em'
                    }}>
                      BatteryGoWhere
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <br />
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      error={emailErr}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event => setEmail(event.target.value),
                        type: "email",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),

                      }}
                    />
                    <CustomInput
                      labelText="Password"
                      id="Password"
                      error={passwordErr}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event => setpassword(event.target.value),
                        type: "password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                  </CardBody>
                  <br />
                  <br />
                  <br />
                  <CardFooter className={classes.cardFooter}>
                    <Button type="submit" color="primary" size="lg">
                      Login
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
          <LoginErrModal open={showLoginErrMessage} message={message} />
        </div>
      </div>
    </div>
  );
}
