/*eslint-disable*/
import React, { useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link, useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps } from "@material-ui/icons";
import PersonIcon from '@material-ui/icons/Person';
import AccountCircle from "@material-ui/icons/AccountCircle";

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import { maxWidth } from "@mui/system";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const [usernameStorage, getStorage] = useState(localStorage.getItem('username'))

  const disable = false;
  const Logout = e => {
    localStorage.clear();
    getStorage('');
  }


  return (
    <div style={{ display: "flex" }}>
      <div style={{
        height: "auto",
        display: "flex",
        alignItems: "center",
        width: "1000px"
      }}>
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <Button
              href="/"
              color="transparent"
              className={classes.navLink}>
              <h7 style={{ fontSize: '15px', fontWeight: '400' }}>Home</h7>
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              href="/news"
              color="transparent"
              className={classes.navLink}>
              <h7 style={{ fontSize: '15px', fontWeight: '400' }}>News Feed</h7>
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              href="/forumblogs"
              color="transparent"
              className={classes.navLink}>
              <h7 style={{ fontSize: '15px', fontWeight: '400' }}>Forum Blogs</h7>
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              href="/chargingnavigator"
              color="transparent"
              className={classes.navLink}>
              <h7 style={{ fontSize: '15px', fontWeight: '400' }}>Charging Navigator</h7>
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              href="/evadvisor"
              color="transparent"
              disabled={usernameStorage ? false : true}
              className={classes.navLink}>
              <h7 style={{ fontSize: '15px', fontWeight: '400' }}>EV Advisor</h7>
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              href="/feedbacksurvey"
              color="transparent"
              className={classes.navLink}>
              <h7 style={{ fontSize: '15px', fontWeight: '400' }}>Feedback Survey</h7>
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              href="/myaccount"
              disabled
              color="transparent"
              className={classes.navLink}>
              <h7 style={{ fontSize: '15px', fontWeight: '400' }}>My Account</h7>
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              href="/about"
              color="transparent"
              className={classes.navLink}>
              <h7 style={{ fontSize: '15px', fontWeight: '400' }}>About</h7>
            </Button>
          </ListItem>
        </List >
      </div>
      <div style={{
        height: "50px",
        display: "flex",
        alignItems: "center"
      }}>
        <List>
          {
            usernameStorage ?
              <ListItem className={classes.listItem}>
                <CustomDropdown
                  left
                  hoverColor="info"
                  buttonIcon={AccountCircle}
                  buttonText={<b> {usernameStorage} </b>}
                  buttonProps={{
                    className: classes.navLink,
                    color: "transparent",
                  }}
                  dropdownList={[
                    <Link to="/" className={classes.dropdownLink} onClick={Logout}>
                      Logout
                    </Link>,

                  ]}
                />
              </ListItem>
              :
              <ListItem className={classes.listItem}>
                <CustomDropdown
                  left
                  hoverColor="info"
                  buttonIcon={AccountCircle}
                  buttonText={<b> {usernameStorage} </b>}
                  buttonProps={{
                    className: classes.navLink,
                    color: "transparent",
                  }}
                  dropdownList={[
                    <Link to="/login" className={classes.dropdownLink}>
                      Login
                    </Link>,
                    <Link to="/register" className={classes.dropdownLink}>
                      Register
                    </Link>
                    // <div>
                    //   <a className={classes.dropdownLink} style={{ color: 'GrayText', padding:'0' }}>Register (Comming soon)</a>
                    // </div>

                  ]}
                />
              </ListItem>
          }
        </List>
      </div>
    </div >
  );
}
